// https://www.gatsbyjs.org/docs/adding-tags-and-categories-to-blog-posts/#add-tags-to-your-markdown-files

import React from 'react';
import { Link, graphql } from 'gatsby';
import { kebabCase } from 'lodash';

import Header from '../components/header'
import Layout from '../components/layout';
import Container from '../components/Container';
import Heading from '../components/Heading';
import Box from '../components/Box'
import Listing from '../components/listing'
import TagList from '../components/TagList'
import TagListItem from '../components/TagListItem'
import ShowAll from '../components/ShowAll'
import Grid from '../components/Grid'
import Hero from '../components/Hero'

const JobsPage = ({ data }) => {
  const allTags = data.allMarkdownRemark.group;
  const { totalCount } = data.allMarkdownRemark;
  const tagHeader = `${totalCount} job${
    totalCount === 1 ? '' : 's'
  } in total`;
  return (
    <Layout>
        <Header />
        <Hero title={tagHeader} />
        <Container as="main" id='main-content'>
            <Grid gridTemplateColumns={['none', '1fr']} gridGap={[0, 4]}>
                <Box as="aside">
                    <Heading as="h2" fontSize="3" fontWeight="500">Filter by</Heading>
                    <ShowAll><Link to="/">Show all</Link></ShowAll>
                    <Box mb="4">
                        <TagList>
                        {allTags.map(tag => (
                            <TagListItem key={tag.fieldValue}>
                            <Link to={`/jobs/${kebabCase(tag.fieldValue)}/`}>
                                {tag.fieldValue} ({tag.totalCount})
                            </Link>
                            </TagListItem>
                        ))}
                        </TagList>
                    </Box>
                </Box>
                <Box as="section">
                    <div css="border-bottom: solid 1px #ebebeb">
                      <Heading as="h2" fontSize="3" fontWeight="500" pb="24px">{tagHeader}</Heading>
                    </div>
                    <Box as="ul" p="0">
                        <Listing />
                    </Box>
                </Box>
            </Grid>
        </Container>
    </Layout>
  );
};

export default JobsPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 2000
      filter: { frontmatter: { published: { eq: true } } }
      ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
      totalCount
    }
  }
`;