import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import { kebabCase } from 'lodash';

import Card from './Card'
import Heading from './Heading'
import Text from './Text'
import TagList from '../components/TagList'
import TagListItem from '../components/TagListItem'
import Grid from '../components/Grid'
import Box from '../components/Box'

  
const LISTING_QUERY = graphql`
    query BlogPostListing {
        allMarkdownRemark(
            limit: 2000, 
            filter: { frontmatter: { published: { eq: true } } }
            sort: { order: DESC, fields: [frontmatter___date] }
        ) {
            edges {
                node {
                    excerpt
                    frontmatter {
                        title
                        slug
                        date(formatString: "MMMM DD, YYYY")
                        location
                        image
                        company
                        experience_level
                        position_type
                        tags
                    }
                }
            }
        }
    }

`

const Listing = () => (

 <StaticQuery
    query={LISTING_QUERY}
    render={({allMarkdownRemark}) => (
        allMarkdownRemark.edges.map(({node}) => (
                <li css="list-style: none;">
                    
                        <Card as="article" key={node.frontmatter.slug}>
                            <Grid gridTemplateColumns={['1fr', '200px 4fr']} gridGap={['3', '4']}>
                                <Box mt="3" mb="3" ml="3">
                                    <Link 
                                        to={`/${node.frontmatter.slug}/`}
                                        style={{
                                            textDecoration: 'none',
                                            display: 'block',
                                        }}>
                                            <img src={`${node.frontmatter.image}`} alt={node.frontmatter.company}/>
                                        </Link>
                                </Box>
                                <Box p="3">
                                    <Heading as="h2" fontSize="4" textDecoration="none" mb="1" p="0">
                                        <Link to={`/${node.frontmatter.slug}/`}>{node.frontmatter.title}</Link>
                                    </Heading>
                                    <Text as="p" fontWeight="400">
                                        <Link to={`/jobs-at-${kebabCase(node.frontmatter.company)}/`}>{node.frontmatter.company}</Link>
                                    </Text>
                                    <Box mt="3">
                                    {node.frontmatter.tags ? (       
                                    <TagList>
                                        {node.frontmatter.tags.map(tag => (
                                        <TagListItem key={tag + `tag`}>
                                            <Link to={`/jobs-in-${kebabCase(tag)}/`}>{tag}</Link>
                                        </TagListItem>
                                        ))}
                                    </TagList>
                                    ) : null}
                                    {node.frontmatter.location ? ( 
                                    <TagListItem as="p">
                                        <Link to={`/jobs-in-${kebabCase(node.frontmatter.location)}/`}>
                                            {node.frontmatter.location}
                                        </Link>
                                    </TagListItem>                                       
                                    ) : null}
                                    {node.frontmatter.experience_level ? ( 
                                    <TagListItem as="p">
                                        <Link to={`/${kebabCase(node.frontmatter.experience_level )}-jobs/`}>
                                            {node.frontmatter.experience_level }
                                        </Link>
                                    </TagListItem>                                       
                                    ) : null}
                                    {node.frontmatter.position_type ? ( 
                                    <TagListItem as="p">
                                        <Link to={`/${kebabCase(node.frontmatter.position_type )}-jobs/`}>
                                            {node.frontmatter.position_type }
                                        </Link>
                                    </TagListItem>                                       
                                    ) : null}
                                    </Box>
                                </Box>
                            </Grid>
                        </Card>
                </li>
        ))
    )}
 />

)

export default Listing

